import React, { useState, useEffect, useRef } from "react";
import { Text, TouchableOpacity, View, ActivityIndicator } from "react-native";
import Card from 'react-bootstrap/Card';
import { TextInput, } from "react-native-paper";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import * as Device from 'expo-device';//Device.osName
import { useIsFocused  } from '@react-navigation/native';
const camposvalid = [];
import dotenv from '../../../app.config';

export default function App({route, navigation}){
    const [valuecpf, setValueCPF] = useState('');
    const [valuenome, setValueNOME] = useState('');
    const [valuecep, setValueCEP] = useState('');
    const [valueendereco, setValueEndereco] = useState('');
    const [valuecidade, setValueCidade] = useState('');
    const [valueuf, setValueUf] = useState('');
    const [usecep, setUseCep] = useState(true);
    const [valuenumero, setValueNumero] = useState('');
    const [valueidentidade, setValueIdentidade] = useState('');
    const [valuedtnascimento, setValueDtnascimento] = useState('');
    const [valueantigasenha, setValueAntigaSenha]=useState('');
    const [valuenovasenha, setValueNovaSenha]=useState('');
    const [valueconfsenha, setValueConfSenha]=useState('');
    const [loading, setLoading] = useState(false);

    const camponumero = useRef(null);
    const campocep = useRef(null);

    const [statusnome, setStatusNome]=useState(false);
    const [statusdtnascimento, setStatusDtNascimento]=useState(false);
    const [statusrua, setStatusRua]=useState(false);
    const [statusnumero, setStatusNumero]=useState(false);
    const [statuscep, setStatusCep]=useState(false);
    const [statuscpf, setStatusCpf]=useState(false);
    const [statuscartidentidade, setStatusCartIdentidade]=useState(false);
    const [typerecovery, setTypeRecovery]=useState('');
    

    function namearray(position){
        switch(position) {
            case 0:
              setStatusNome(true);
              return 'nome';            
            case 1:
              setStatusDtNascimento(true);
              return 'dtnascimento';
            case 2:
              setStatusNumero(true);
              return 'numero';
            case 3:
              setStatusCep(true);
              return 'cep';
            case 4:
              setStatusCpf(true)
              return 'cpf';
            default:
              setStatusCartIdentidade(true);
              return 'cartidentidade';ok
          }
    }
    function buscarnumeros(item){
        // Loop em todos os elementos
       var arr = [0,1,2,3,4,5]
       for (let i = arr.length - 1; i > 0; i--) {
            // Escolhendo elemento aleatório
            const j = Math.floor(Math.random() * (i + 1));
            // Reposicionando elemento
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        // Retornando array com aleatoriedade
        //return [arr[0], arr[1], arr[2]];
        camposvalid.push({
            0: namearray(arr[0]),
            1: namearray(arr[1]),
            2: namearray(arr[2]),
            ra: item.ra,
            nome: item.nome,
            dtnascimento: item.dtnascimento,
            numero: item.numero,
            cep: item.cep,
            cpf: item.cpf,
            cartidentidade: item.cartidentidade,
            email: item.email            

        })
        //alert(JSON.stringify(camposvalid[0].typerecovery));
        
        //alert(`Item 1: ${namearray(arr[0])}, Item 2: ${namearray(arr[1])}, Item 3: ${namearray(arr[2])}`);
    }

    const focus = useIsFocused();  // useIsFocused as shown 
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
            if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
                //alert(JSON.stringify(route.params.route))
                buscarnumeros(route.params.route);
                setTypeRecovery(route.params.route.typerecovery);
                //typerecovery = 0 (trocasenha), 1 (recuprar senha email), 3 (já tem o token, trocar a senha) 
                //alert(JSON.stringify(camposvalid));
                return false;
            }
    }, [focus]);

    async function IniciarDados(){
                               
            if(statusnome){
                    if(valuenome !==camposvalid[0].nome ){
                        alert(`Infelizmente não foi validado seus dados, não podemos continuar.`);
                        return false;
                    }
            }
            if(statusdtnascimento){
                if(valuedtnascimento !== camposvalid[0].dtnascimento ){
                    alert(`Infelizmente não foi validado seus dados, não podemos continuar.`);
                    return false;
                }
            }
            if(statusnumero){
                if(valuenumero !== camposvalid[0].numero ){
                    alert(`Infelizmente não foi validado seus dados, não podemos continuar.`);
                    return false;
                }
            }
            if(statuscep){
                if(valuecep !== camposvalid[0].cep ){
                    alert(`Infelizmente não foi validado seus dados, não podemos continuar.`);
                    return false;
                }
            }
            if(valuecpf){
                if(valuecpf !== camposvalid[0].cpf ){
                    alert(`Infelizmente não foi validado seus dados, não podemos continuar.`);
                    return false;
                }
            }
            if(statuscartidentidade){
                if(valueidentidade !== camposvalid[0].cartidentidade ){
                    alert(`Infelizmente não foi validado seus dados, não podemos continuar.`);
                    return false;
                }
            }
            setLoading(true);


            let url = `https://restlicense.t2kconsultoria.com.br/validtoken`;
        //valid token
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                sistema:'literatusempresacontrato',
                token: token
            }),
            timeout: 30000,
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            },
          })
        .then((response) => response.json())
        .then((responseJson) => {
            //
            if (responseJson.acesso == true){
                //setValid(true);
                if(typerecovery=='1'){
                    fetch(`${dotenv.RESTGESTOR}/senha`, {
                        method: 'POST',
                        body: JSON.stringify({
                            ra: camposvalid[0].ra,
                            //pwdold: valueantigasenha,
                            //pwdnew: valuenovasenha,
                            //pwdconf: valueconfsenha,
                            tiporeset: 0,
                            email: camposvalid[0].email
                        }),
                        //timeout: 5000,
                        redirect: 'follow',
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            //"x-access-token": user.token,
                        },
                    })
                    .then((response) => response.json())
                    .then((responseJson) => {   
                            setLoading(false);
                            if (responseJson.validtoken==false){


                                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                    '## TOKEN - EXPIRADO ##', // This is a title
                                    `${responseJson.message}`,
                                    {
                                        textConfirm: 'OK',      // Label of button confirm
                                        textCancel: '',     // Label of button cancel
                                        onCancel: () => { { } }, // Call your cancel function 
                                        onConfirm: () => { { 
                                            signOut();
                                        } } // Call your confirm function 
                                    }
                                )
                                return
                            }
                            
                            if(responseJson.sucesso == true){
                                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                    '## SENHA ##', // This is a title
                                    `${responseJson.message}\n\nAo ser receber o Token, selecione a opção "JÁ TENHO TOKEN".`,
                                    {
                                        textConfirm: 'OK',      // Label of button confirm
                                        textCancel: '',         // Label of button cancel
                                        onCancel: () =>  { {} },// Call your cancel function 
                                        onConfirm: () => { {
                                            window.location.reload(false);
                                        } } // Call your confirm function 
                                    }
                                )
                                //setSenhaNew('');
                                //setSenhaOld('');
                                //setConfSenhaNew('');
                            }else{

                                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                    '## SENHA ##', // This is a title
                                    `${responseJson.message}`,
                                    {
                                        textConfirm: 'OK',      // Label of button confirm
                                        textCancel: '',         // Label of button cancel
                                        onCancel: () =>  { {} },// Call your cancel function 
                                        onConfirm: () => { {} } // Call your confirm function 
                                    }
                                )  
                                                    
                            }
                            //setLoadingPwd(false);
                            //setModalVisible(!modalVisible);
                    })
                    .catch((error) => {
                        setLoading(false);
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## TROCA SENHA ##', // This is a title
                            `${error}`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',         // Label of button cancel
                                onCancel: () =>  { {} },// Call your cancel function 
                                onConfirm: () => { {} } // Call your confirm function 
                            }
                        )
                    //  setLoadingPwd(false);                                     
                    })
                    .finally(function () { 
                        setLoading(false);
                    });
            }
            
            if(typerecovery=='0'){
                    fetch(`${dotenv.RESTGESTOR}/senha`, {
                        method: 'POST',
                        body: JSON.stringify({
                            ra: camposvalid[0].ra,
                            pwdold: valueantigasenha,
                            pwdnew: valuenovasenha,
                            pwdconf: valueconfsenha,
                            tiporeset: 1,
                            email: camposvalid[0].email
                        }),
                        //timeout: 5000,
                        redirect: 'follow',
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            //"x-access-token": user.token,
                        },
                    })
                    .then((response) => response.json())
                    .then((responseJson) => {   
                            setLoading(false);
                            if (responseJson.validtoken==false){
                                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                    '## TOKEN - EXPIRADO ##', // This is a title
                                    `${responseJson.message}`,
                                    {
                                        textConfirm: 'OK',      // Label of button confirm
                                        textCancel: '',     // Label of button cancel
                                        onCancel: () => { { } }, // Call your cancel function 
                                        onConfirm: () => { { 
                                            signOut();
                                        } } // Call your confirm function 
                                    }
                                )
                                return
                            }
                            
                            if(responseJson.sucesso == true){
                                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                    '## SENHA ##', // This is a title
                                    `${responseJson.message}`,
                                    {
                                        textConfirm: 'OK',      // Label of button confirm
                                        textCancel: '',         // Label of button cancel
                                        onCancel: () =>  { {} },// Call your cancel function 
                                        onConfirm: () => { {
                                            window.location.reload(false);
                                        } } // Call your confirm function 
                                    }
                                )
                                //setSenhaNew('');
                                //setSenhaOld('');
                                //setConfSenhaNew('');
                            }else{

                                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                    '## SENHA ##', // This is a title
                                    `${responseJson.message}`,
                                    {
                                        textConfirm: 'OK',      // Label of button confirm
                                        textCancel: '',         // Label of button cancel
                                        onCancel: () =>  { {} },// Call your cancel function 
                                        onConfirm: () => { {} } // Call your confirm function 
                                    }
                                )  
                                                    
                            }
                            //setLoadingPwd(false);
                            //setModalVisible(!modalVisible);
                    })
                    .catch((error) => {
                        setLoading(false);
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## TROCA SENHA ##', // This is a title
                            `${error}`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',         // Label of button cancel
                                onCancel: () =>  { {} },// Call your cancel function 
                                onConfirm: () => { {} } // Call your confirm function 
                            }
                        )
                    //  setLoadingPwd(false);                                     
                    })
                    .finally(function () { 
                        setLoading(false);
                    });
            }                
                
            }else{
                return false;
            }
        })
        .catch((error) => {
            return false;
        })
        .finally(function () {});  

    }

    return (
        <View style={{position:'relative', backgroundColor: '#004b9e', width: '100vw', height: '100vh', overflow:'hidden', alignItems: 'center', justifyContent:'center'}}>
        <Image style={{position: 'absolute'}}  src={require('../../imagem/tela-portal.png')}  fluid />
        
        <Card  style={{ width: Device.osName=='Windows' ? '50vw' : '80%', padding: 10, flexWrap: 'wrap' }}>
            <Form>
                <Form.Text className="justify-content-center" muted style={{fontSize: Device.osName=='Windows' ? null : 13}}>
                    {`CONFIRME SEUS DADOS PARA PODERMOS CONTINUAR\nOBS: OS DADOS DEVEM SER O MESMO NO CADASTRO LITERATUS`}
                </Form.Text>
                {statuscpf ? (
                <Row className="mb-3">                    
                    <Form.Group as={Col}>
                        <TextInput
                            label={'CPF'}
                            //style={{backgroundColor: '#ddd'}}
                            mode='flat'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valuecpf}
                            onChangeText={(text) => {setValueCPF(text)}}
                            left={<TextInput.Icon icon="card-bulleted-settings" disabled={true}/>}
                            maxLength={11}
                        />
                    </Form.Group>
                    
                </Row>
                ):null}
                {statusnome ? (
                <Row className="mb-3">                    
                    <Form.Group as={Col}>
                    <TextInput
                        label={'Nome Completo'}
                        //style={{padding: 10}}
                        mode='flat'
                        selectionColor={'#004b9e'}
                        underlineColor={'#004b9e'}
                        activeUnderlineColor={'#004b9e'}
                        outlineColor={'#004b9e'}
                        activeOutlineColor={'#004b9e'}
                        textColor={'#004b9e'}
                        dense={true}
                        value={valuenome}
                        onChangeText={(text) => {setValueNOME(text.toUpperCase())}}
                        left={<TextInput.Icon icon="account" disabled={true}/>}                              
                    />
                    </Form.Group>                    
                </Row>
                ):null}
                {statuscep ? (
                <Row className="mb-3">                    
                    <Form.Group as={Col}>
                        <TextInput
                            label={'CEP Residência'}
                            //style={{backgroundColor: '#ddd'}}
                            mode='flat'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valuecep}
                            onChangeText={(text) => {setValueCEP(text)}}
                            left={<TextInput.Icon icon="mailbox" disabled={true}/>}      
                            maxLength={8}                            
                        />
                    </Form.Group>  
                                                                       
                </Row>
                ): null}   
                {statusnumero ? (
                <Row className="mb-3">                    
                    <Form.Group as={Col}>
                        <TextInput
                            label={'Número Residência'}
                            //style={{padding: 10}}
                            mode='flat'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valuenumero}
                            onChangeText={(text) => {setValueNumero(text.toUpperCase())}}
                            left={<TextInput.Icon icon="home-floor-1" disabled={true}/>}                    
                        />
                    </Form.Group>
                    
                </Row>
                ): null}
                {statuscartidentidade ? (
                <Row className="mb-3">                    
                    <Form.Group as={Col}>
                        <TextInput
                            label={'Cart. Identidade (RG)'}
                            mode='flat'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valueidentidade}
                            onChangeText={(text) => {setValueIdentidade(text)}}
                            left={<TextInput.Icon icon="page-previous" disabled={true}/>}                    
                        />
                    </Form.Group>
                    
                </Row>
                ): null}

                {statusdtnascimento? (
                <Row className="mb-3">                    
                    <Form.Group as={Col}>
                        <TextInput
                            label={'Data Nascimento'}
                            mode='flat'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valuedtnascimento}
                            onChangeText={(text) => {setValueDtnascimento(text)}}
                            left={<TextInput.Icon icon="calendar-month" disabled={true}/>}                    
                        />
                    </Form.Group>                    
                </Row>
                ): null}

                {typerecovery == '0' ? (
                <Row className="mb-3">                    
                    <Form.Group as={Col}>
                        <TextInput
                            label={'Antiga Senha'}
                            style={{width: Device.osName=='Windows' ? 200 : null}}
                            mode='flat'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valueantigasenha}
                            onChangeText={(text) => {setValueAntigaSenha(text)}}
                            secureTextEntry={true}
                            left={<TextInput.Icon icon="form-textbox-password" disabled={true}/>}                    
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <TextInput
                            label={'Nova Senha'}
                            style={{width: Device.osName=='Windows' ? 200 : null}}
                            mode='flat'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valuenovasenha}
                            onChangeText={(text) => {setValueNovaSenha(text)}}
                            secureTextEntry={true}
                            left={<TextInput.Icon icon="form-textbox-password" disabled={true}/>}                    
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <TextInput
                            label={'Confirma Senha'}
                            style={{width: Device.osName=='Windows' ? 200 : null}}
                            mode='flat'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valueconfsenha}
                            onChangeText={(text) => {setValueConfSenha(text)}}
                            secureTextEntry={true}
                            left={<TextInput.Icon icon="form-textbox-password" disabled={true}/>}                    
                        />
                    </Form.Group>                    
                </Row>
                ): null}

                
                <View style={{position: 'relative', alignItems: 'center', justifyContent: 'center', margin: 30}}>
                    <Form.Group as={Col}>
                        <TouchableOpacity
                            style={{width: 200, height: 30, backgroundColor: '#004b9e', borderRadius: 5, padding: 5, alignItems:'center', justifyContent: 'center'}}
                            onPress={() => IniciarDados() }
                        >
                            {loading ? (
                                <ActivityIndicator size={20} color='#FFF' />
                            ):(
                                <Text style={{color:'#FFF', fontSize: 15, fontWeight: 'bold'}}>Avançar</Text>
                            )}
                        </TouchableOpacity>
                    </Form.Group>
                </View>

                
                </Form>
        </Card>
        </View>
    )
}