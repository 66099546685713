import React, {} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SuperAlert from 'react-native-super-alert';
import FlashMessage from 'react-native-flash-message';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Initial from '../src/screen/initial';
import Dados from './screen/dados';
const Stack = createNativeStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
                <Stack.Navigator
                            initialRouteName="Initial"
                            screenOptions={screenOptions}>
                            <Stack.Screen
                                name='Initial'
                                component={Initial}
                                options={({ navigation }) => {
                                    return{
                                        headerTintColor: '#FFF',
                                        headerShown: false,
                                        title:  'Literatus Troca Senha',
                                    }
                                }}
                            />
                            <Stack.Screen
                                name='Dados'
                                component={Dados}
                                options={({ navigation }) => {
                                    return{
                                        headerTintColor: '#FFF',
                                        headerShown: false,
                                        title:  'Literatus Troca Senha',
                                    }
                                }}
                            />
              </Stack.Navigator>
    <FlashMessage position="top" />
    <SuperAlert customStyle={customStyle} />
    </NavigationContainer>
  
  );
}

const screenOptions = {
    headerStyle: {
        backgroundColor: '#f4511e'
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
        fontWeight: 'bold'
    },
  
  }
  

const customStyle = {
    container: {
        backgroundColor: '#e8e8e8',
        borderRadius: 10,
        //position: 'absolute', 
        zIndex: 2000
        //paddingTop: 0,
    },
    buttonCancel: {
        backgroundColor: '#b51919',
        borderRadius: 5,
        width: 90,
        height: 35,
        left: -30,
    },
    buttonConfirm: {
        backgroundColor: '#4490c7',
        borderRadius: 5,
        width: 90,
        height: 35,
        left: 30,
    },
    textButtonCancel: {
        color: '#fff',
        fontWeight: 'bold'
    },
    textButtonConfirm: {
        color: '#fff',
        fontWeight: 'bold'
    },
    title: {
        color: '#003d69',
        fontSize: 15
    },
    message: {
        color: '#4f4f4f',
        fontSize: 12,
        fontWeight: 'bold',
        paddingBottom: 25,
    }
  }


