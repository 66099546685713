import React, { useState, useRef, useEffect } from "react";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import Card from 'react-bootstrap/Card';
import { TextInput, RadioButton, } from "react-native-paper";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import * as Device from 'expo-device';//Device.osName
const dotenv = require('../../../app.config');
import { useFonts } from 'expo-font';
import { useIsFocused  } from '@react-navigation/native';

export default function App({navigation}){
    const [valuera, setValueRA] = useState('');
    const [valueemail, setValueEMAIL] = useState('');
    const campora = useRef(null);
    const campoemail = useRef(null);
    const [checked, setChecked]=useState('');
    const [valuetoken, setValueToken]=useState('');
    const [valuenovasenha, setValueNovaSenha]=useState('');
    const [valueconfsenha, setValueConfSenha]=useState('');
    const [loading, setLoading]=useState(false);

    const [liberado, setLiberado]=useState(true);

    const [fontsLoaded] = useFonts({
        'Gilroy-Bold': require('../../fonts/Gilroy-Bold.ttf'),
        'Gilroy-Heavy': require('../../fonts/Gilroy-Heavy.ttf'),
      });

      const focus = useIsFocused();  // useIsFocused as shown 
        useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
            if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
                acessopage();
            }
        }, [focus]);
   

    async function acessopage(){
        let url = `https://restlicense.t2kconsultoria.com.br/acesso`;
        //valid token
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                sistema:'literatus',
            }),
            timeout: 30000,
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            },
          })
        .then((response) => response.json())
        .then((responseJson) => {
            //
            if (responseJson.acesso == true){
                setLiberado(false)
                //return false;
            }else{
                setLiberado(true)
                //return false;
            }
        })
        .catch((error) => {})
        .finally(function () {});  
    }

    async function IniciarDados(){
        try {

                
        if (valuera === ''){
            alert('RA informado inválido!')
            return false
        }

        if(valueemail === ''){
            alert( 'Email informado inválido!');
            return false;
        }
           
            await fetch( `${dotenv.LITERATUS}/recoverypassword`, {
                    method: 'POST',
                    body: JSON.stringify({
                        ra: valuera,
                        email: valueemail
                    }),
                    timeout: 5000,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                      //"x-access-token": user.token,
                    },
                  })
                .then((response) => response.json())
                .then((responseJson) => {
                    //console.log(JSON.stringify(responseJson))
                    if(responseJson.sucesso ==true){
                    let dados = {
                            ra: responseJson.dados[0].ra,
                            nome: responseJson.dados[0].nome,
                            dtnascimento: responseJson.dados[0].dtnascimento,
                            numero: responseJson.dados[0].numero,
                            cep: responseJson.dados[0].cep,
                            cpf: responseJson.dados[0].cpf,
                            cartidentidade: responseJson.dados[0].cartidentidade,
                            email: responseJson.dados[0].email,
                            typerecovery: checked
                    }
                    navigation.navigate('Dados', {route: dados, navigation: navigation});                    
                    //alert(JSON.stringify(dados))
                }else{
                    alert(responseJson.message)
                }
                })
                .catch((error) => {})
                .finally(function () {});  
        } catch {{}} 
          finally {{}}
    }

    async function resetsenhatoken(){
        if(checked=='2'){
                setLoading(true);
                await fetch(`${dotenv.RESTGESTOR}/senha`, {
                    method: 'POST',
                    body: JSON.stringify({
                        ra: valuera,
                        pwdold: valuetoken,
                        pwdnew: valuenovasenha,
                        pwdconf: valueconfsenha,
                        tiporeset: 1
                        //email: camposvalid[0].email
                    }),
                    //timeout: 5000,
                    redirect: 'follow',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        //"x-access-token": user.token,
                    },
                })
                .then((response) => response.json())
                .then((responseJson) => {   
                        setLoading(false);
                        if (responseJson.validtoken==false){
                            alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                '## TOKEN - EXPIRADO ##', // This is a title
                                `${responseJson.message}`,
                                {
                                    textConfirm: 'OK',      // Label of button confirm
                                    textCancel: '',     // Label of button cancel
                                    onCancel: () => { { } }, // Call your cancel function 
                                    onConfirm: () => { { 
                                        signOut();
                                    } } // Call your confirm function 
                                }
                            )
                            return
                        }
                        
                        if(responseJson.sucesso == true){
                            alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                '## SENHA ##', // This is a title
                                `${responseJson.message}`,
                                {
                                    textConfirm: 'OK',      // Label of button confirm
                                    textCancel: '',         // Label of button cancel
                                    onCancel: () =>  { {} },// Call your cancel function 
                                    onConfirm: () => { {
                                        window.location.reload(false);
                                    } } // Call your confirm function 
                                }
                            )
                            
                        }else{

                            alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                '## SENHA ##', // This is a title
                                `${responseJson.message}`,
                                {
                                    textConfirm: 'OK',      // Label of button confirm
                                    textCancel: '',         // Label of button cancel
                                    onCancel: () =>  { {} },// Call your cancel function 
                                    onConfirm: () => { {} } // Call your confirm function 
                                }
                            )  
                                                
                        }
                        //setLoadingPwd(false);
                        //setModalVisible(!modalVisible);
                })
                .catch((error) => {
                    setLoading(false);
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## TROCA SENHA ##', // This is a title
                        `${error}`,
                        {
                            textConfirm: 'OK',      // Label of button confirm
                            textCancel: '',         // Label of button cancel
                            onCancel: () =>  { {} },// Call your cancel function 
                            onConfirm: () => { {} } // Call your confirm function 
                        }
                    )
                //  setLoadingPwd(false);                                     
                })
                .finally(function () { 
                    setLoading(false);
                });
        }
    }

    

    return (
        <View style={{position:'relative', backgroundColor: '#004b9e', width: '100vw', height: '100vh', overflow:'hidden', alignItems: 'center', justifyContent:'center'}}>
        <Image style={{position: 'absolute'}}  src={require('../../imagem/tela-portal.png')}  fluid />
        <Text style={{position: 'absolute', fontFamily: 'Gilroy-Heavy', fontSize: Device.osName=='Windows' ? 40 : 25, color:'#FFD700', fontWeight: Device.osName=='Windows' ? 'bold' : null, alignItems:'center',justifyContent:'center', margin: 10, marginTop: Device.osName=='Windows' ? -450 : -300,}}>TROCA SENHA LITERATUS</Text>
        <Card  style={{ width: Device.osName=='Windows' ? 600 : '80%', padding: 20 }}>
            <Form>
                <Form.Text className="justify-content-center" muted style={{fontSize: Device.osName=='Windows' ? null : 13}}>
                    INFORME SEU DADOS PARA PODERMOS INICIAR
                </Form.Text>
                {liberado==true ? (
                <Form.Text className="justify-content-center" muted style={{fontSize: Device.osName=='Windows' ? null : 11}}>
                    Erro conexão com Servidor
                </Form.Text>
                ):null}
                <Row style={{position: 'relative', margin: 5}}>
                    <Form.Group as={Col} >
                        <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width: Device.osName=='Windows' ? 135 : 200}}>
                            <Text style={{fontWeight:'bold'}}>TROCA SENHA</Text>
                            <RadioButton
                            value="0"
                            status={ checked === '0' ? 'checked' : 'unchecked' }
                            onPress={() => setChecked('0')}
                            disabled={liberado}          
                            />
                        </View>
                    </Form.Group> 
                    <Form.Group as={Col} style={{flexDirection:'row'}}>
                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width: Device.osName=='Windows' ? 160 : 200}}>
                        <Text style={{fontWeight:'bold'}}>RECUPERA SENHA</Text>
                        <RadioButton
                            value="1"
                            status={ checked === '1' ? 'checked' : 'unchecked' }
                            onPress={() => setChecked('1')}
                            disabled={liberado}          
                        />   
                    </View>
                    </Form.Group> 
                    <Form.Group as={Col} style={{flexDirection:'row'}}>
                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width: Device.osName=='Windows' ? 160 : 200}}>
                        <Text style={{fontWeight:'bold'}}>JÁ TENHO TOKEN</Text>
                        <RadioButton
                            value="2"
                            status={ checked === '2' ? 'checked' : 'unchecked' }
                            onPress={() => setChecked('2')}
                            disabled={liberado}          
                        />   
                    </View>
                    </Form.Group> 
                </Row>
                {checked=='0' || checked=='1' || checked=='2' ? (
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <TextInput
                            label={'RA Aluno'}
                            ref={campora}
                            //style={{backgroundColor: '#ddd'}}
                            mode='flat'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valuera}
                            onChangeText={(text) => {setValueRA(text)}}
                            left={<TextInput.Icon icon="badge-account-horizontal" disabled={true}/>}
                            onSubmitEditing={()=> campoemail.current.focus()}                                            
                        />
                    </Form.Group>
                </Row>
                ):null}
                {checked=='0' || checked=='1' ? (                
                <Row className="mb-3">
                    <Form.Group as={Col}>
                    <TextInput
                        label={'Email Aluno'}
                        ref={campoemail}
                        //style={{padding: 10}}
                        mode='flat'
                        selectionColor={'#004b9e'}
                        underlineColor={'#004b9e'}
                        activeUnderlineColor={'#004b9e'}
                        outlineColor={'#004b9e'}
                        activeOutlineColor={'#004b9e'}
                        textColor={'#004b9e'}
                        dense={true}
                        value={valueemail}
                        onChangeText={(text) => {setValueEMAIL(text.toLowerCase())}}
                        left={<TextInput.Icon icon="card-account-mail" disabled={true}/>}
                        onSubmitEditing={()=> IniciarDados()}                                          
                    />
                    </Form.Group>
                </Row>                
                ):null}
                {checked == '2' ? (
                <>
                <Row className="mb-3">                    
                    <Form.Group as={Col}>
                        <TextInput
                            label={'Cole aqui o Token'}
                            mode='flat'
                            multiline={true}
                            numberOfLines={3}
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valuetoken}
                            onChangeText={(text) => {setValueToken(text)}}
                            left={<TextInput.Icon icon="form-textbox-password" disabled={true}/>}                    
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3"> 
                    <Form.Group as={Col}>
                        <TextInput
                            label={'Nova Senha'}
                            mode='flat'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valuenovasenha}
                            onChangeText={(text) => {setValueNovaSenha(text)}}
                            secureTextEntry={true}
                            left={<TextInput.Icon icon="form-textbox-password" disabled={true}/>}                    
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3"> 
                    <Form.Group as={Col}>
                        <TextInput
                            label={'Confirma Senha'}
                            mode='flat'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            value={valueconfsenha}
                            onChangeText={(text) => {setValueConfSenha(text)}}
                            secureTextEntry={true}
                            left={<TextInput.Icon icon="form-textbox-password" disabled={true}/>}                    
                        />
                    </Form.Group>                    
                </Row>
                </>
                ): null}
                {checked != '' ? (
                <View style={{position: 'relative', alignItems: 'center', justifyContent: 'center'}}>
                    <Form.Group as={Col}>
                        <TouchableOpacity
                            style={{width: 200, height: 30, backgroundColor: '#004b9e', borderRadius: 5, padding: 5, alignItems:'center', justifyContent: 'center'}}
                            onPress={() => {
                                {checked=='0' || checked=='1' ? (
                                    IniciarDados()
                                ) : (
                                    resetsenhatoken()
                                )}                              
                            }}
                        >
                            {loading ? (
                                <ActivityIndicator size={20} color='#FFF' />
                            ):(
                                <Text style={{color:'#FFF', fontSize: 15, fontWeight: 'bold'}}>Avançar</Text>
                            )}
                        </TouchableOpacity>
                    </Form.Group>
                </View>
                ):null}
                
                </Form>
        </Card>
        </View>
    )
}